const Months = Object.freeze({
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
});

function monthsTill(month: number): number[] {
  return Object.values(Months).filter((m) => m <= month);
}

export { Months, monthsTill };

class Year {
  readonly _year: number;

  constructor(year: number) {
    if (Number.isInteger(year) && year >= 2000 && year <= 2100) {
      this._year = year;
    } else {
      throw Error(`Year cannot be ${year}`);
    }
  }

  equalTo(other: Year | number): boolean {
    let year;
    if (typeof other == "number") {
      year = other;
    } else {
      year = other._year;
    }
    return this._year === year;
  }

  earlierThen(other: Year): boolean {
    return this._year < other._year;
  }

  laterThen(other: Year): boolean {
    return this._year > other._year;
  }

  toString() {
    return `Year(${this._year})`;
  }

  static from(year: number): Year {
    return new Year(year);
  }
}

class Month {
  readonly _month: number;
  constructor(month: number) {
    if (month < 1 || month > 12) {
      throw Error("Month needs to be between 1 and 12");
    }
    this._month = month;
  }

  equalTo(other: Month | number): boolean {
    let month: number;
    if (typeof other == "number") {
      month = other;
    } else {
      month = other._month;
    }
    return this._month === month;
  }

  earlierThen(other: Month): boolean {
    return this._month < other._month;
  }

  laterThen(other: Month): boolean {
    return this._month > other._month;
  }

  toString() {
    return `Month(${this._month})`;
  }
}

class MonthOfYear {
  month: Month;
  year: Year;
  constructor(month: Month, year: Year) {
    this.month = month;
    this.year = year;
  }

  toString(): string {
    return `MonthOfYear(${this.month.toString()}, ${this.year.toString()})`;
  }

  equalTo(other: MonthOfYear): boolean {
    if (other instanceof MonthOfYear) {
      return this.year.equalTo(other.year) && this.month.equalTo(other.month);
    } else {
      throw Error(`Cannot compare MonthOfYear to ${other}`);
    }
  }
  static from(month: number | Month, year: number | Year): MonthOfYear {
    let parsedMonth: Month;
    if (month instanceof Month) {
      parsedMonth = month;
    } else {
      parsedMonth = new Month(month);
    }
    let parsedYear: Year;
    if (year instanceof Year) {
      parsedYear = year;
    } else {
      parsedYear = new Year(year);
    }
    return new MonthOfYear(parsedMonth, parsedYear);
  }
}

class Period {
  readonly _start: Date;
  readonly _end: Date;

  constructor(start: Date, end: Date) {
    this._start = start;
    this._end = end;
  }

  firstMonth(year: Year): Month {
    if (
      year.laterThen(new Year(2018)) &&
      year.equalTo(this._start.getFullYear())
    ) {
      return new Month(this._start.getMonth() + 1);
    } else {
      return new Month(Months.JANUARY);
    }
  }

  lastMonth(year: Year): Month {
    if (year.equalTo(this._end.getFullYear())) {
      return new Month(this._end.getMonth() + 1);
    } else {
      return new Month(Months.DECEMBER);
    }
  }

  firstYear(): Year {
    return new Year(this._start.getFullYear());
  }

  lastYear(): Year {
    return new Year(this._end.getFullYear());
  }

  years(year?: Year): Year[] {
    const start =
      this._start.getFullYear() > 2018 ? this._start.getFullYear() : 2019;
    const end = year === undefined ? this._end.getFullYear() : year._year;
    return Array.from(
      { length: end - start + 1 },
      (_, index) => new Year(start + index),
    );
  }

  months(year: Year, month?: Month): number[] {
    const start = this.firstMonth(year)._month;
    const end =
      month === undefined ? this.lastMonth(year)._month : month._month;
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }
}

export { Year, Month, MonthOfYear, Period };
