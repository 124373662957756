import { Contribution } from "./contribution";
import { MonthOfYear } from "./dates";

class Contributions {
  private contributions: Contribution[];

  constructor(contributions: Contribution[]) {
    this.contributions = contributions;
  }

  for(monthOfYear: MonthOfYear): Contributions {
    return new Contributions(
      this.contributions.filter((contribution) =>
        contribution.date.equalTo(monthOfYear),
      ),
    );
  }

  sum(): number {
    return this.contributions.reduce(
      (sum, contribution) => sum + contribution.amount,
      0,
    );
  }
}

export { Contributions };
