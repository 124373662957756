import { Member } from "./member";
import { MonthOfYear, Year } from "./dates";

function cost(member: Member, monthOfYear: MonthOfYear): number {
  if (member.hasJoinedOn(monthOfYear) && member.joinedAfter(2023)) {
    return 0;
  } else {
    return monthOfYear.year.earlierThen(Year.from(2023)) ? 25 : 30;
  }
}

export { cost };
