import { Action } from "./action";
import { MonthOfYear } from "./dates";

class Actions {
  private actions: Action[];

  constructor(actions: Action[]) {
    this.actions = actions;
  }

  for(monthOfYear: MonthOfYear): Actions {
    return new Actions(
      this.actions.filter((action) => {
        action.from.equalTo(monthOfYear);
      }),
    );
  }

  remissions(): Action[] {
    return this.actions.filter((action) => action.type === 2);
  }

  balanceSettings(): Action[] {
    return this.actions.filter((action) => action.type === 1);
  }

  exemptions(): Action[] {
    return this.actions.filter((action) => action.type === 3);
  }
}
export { Actions };
