import { MonthOfYear } from "./dates";
import { RawActionV2 } from "./containers";

class Action {
  type: number;
  from: MonthOfYear;
  till?: MonthOfYear;
  amount?: number;

  constructor({
    type,
    from,
    till,
    amount,
  }: {
    type: number;
    from: MonthOfYear;
    till?: MonthOfYear;
    amount?: number;
  }) {
    this.type = type;
    this.from = from;
    this.till = till;
    this.amount = amount;
  }

  static from(raw: RawActionV2): Action {
    const rawFrom = new Date(raw["data_od"]);

    const from = MonthOfYear.from(
      rawFrom.getMonth() + 1,
      rawFrom.getFullYear(),
    );
    if (raw["data_do"] !== null) {
      const rawTill = new Date(raw["data_do"]);
      const till = MonthOfYear.from(
        rawTill.getMonth() + 1,
        rawTill.getFullYear(),
      );
      return new Action({
        type: parseInt(raw["typ"]),
        from: from,
        till: till,
        amount: raw["kwota"],
      });
    } else {
      return new Action({
        type: parseInt(raw["typ"]),
        from: from,
        amount: raw["kwota"],
      });
    }
  }
}

export { Action };
