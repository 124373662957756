import { MonthOfYear, Months, monthsTill, Period } from "./dates";
import { Fee, MoneyEvent, Payment, Reset } from "./events";
import { Exemption } from "./exemption";

class Member {
  private readonly _id?: number;
  private readonly _joinedOn: Date;
  private readonly _balance: number;
  private readonly _owes: Fee[];
  private readonly _paid: Payment[];
  private readonly _resets: Reset[];
  private readonly _exemptions: any[];

  constructor(balance: number, date: string, id?: number) {
    this._id = id;
    this._joinedOn = new Date(date);
    this._balance = balance;
    this._owes = [];
    this._paid = [];
    this._resets = [];
    this._exemptions = [];
  }
  get id(): number {
    return this._id;
  }

  get joiningYear(): number {
    return this._joinedOn.getFullYear();
  }

  isYearOfJoining(year: number): boolean {
    return this.joiningYear == year;
  }

  get joiningMonth(): number {
    return Object.values(Months)[this._joinedOn.getMonth()];
  }

  balance(year: number, month: number): number {
    if (this.joinedAfter(year)) {
      return 0;
    }
    if (this._balance === null && !this.isYearOfJoining(year)) {
      return null;
    }
    let startBalance = this._balance;
    const balanceByMonth = {};
    for (let m of monthsTill(month)) {
      let balance;
      if (this.hasBalanceBeenResetAt(year, m)) {
        balance = this.balanceReset(year, m);
      } else {
        let owes = this.owesFor(year, m);
        let paid = this.paidFor(year, m);
        balance = startBalance + paid - owes;
      }
      balanceByMonth[m] = balance;
      startBalance = balance;
    }
    return balanceByMonth[month];
  }

  private hasBalanceBeenResetAt(year: number, m: number): boolean {
    return this.balanceReset(year, m) !== undefined;
  }

  private balanceReset(year: number, month: number): number {
    const resets = this._resets.filter(this.for(year, month));
    if (resets.length > 0) {
      return resets.at(-1).amount;
    }
  }

  private paidFor(year: number, month: number): number {
    const payments = this._paid
      .filter(this.for(year, month))
      .map((paid) => paid.amount);
    if (payments.length > 0) {
      return payments.reduce(this.sum);
    } else {
      return 0;
    }
  }

  private owesFor(year: number, month: number): number {
    if (
      this._exemptions.filter((exemption) => exemption.applies(year, month))
        .length > 0
    ) {
      return 0;
    } else {
      return this._owes
        .filter(this.for(year, month))
        .map((owes) => owes.amount)
        .reduce(this.sum);
    }
  }

  private for(year: number, month: number): (element: MoneyEvent) => boolean {
    return (element) => element.year == year && element.month == month;
  }

  private sum(a: number, b: number): number {
    return a + b;
  }

  owes(fee: Fee): void {
    this._owes.push(fee);
  }

  paid(payment: Payment): void {
    this._paid.push(payment);
  }

  exempt(exemption: Exemption): void {
    this._exemptions.push(exemption);
  }

  reset(reset: Reset): void {
    this._resets.push(reset);
  }

  joinedAfter(year: number): boolean {
    return this.joiningYear > year;
  }

  owesForPeriod(): Period {
    return new Period(this._joinedOn, new Date());
  }

  hasJoinedOn(monthOfYear: MonthOfYear): boolean {
    return monthOfYear.equalTo(
      MonthOfYear.from(this.joiningMonth, this.joiningYear),
    );
  }
}

export { Member };
