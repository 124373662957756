import { RawContribution } from "./containers";
import { MonthOfYear, Year } from "./dates";

class Contribution {
  id: number;
  member_id: number;
  type: number;
  date: MonthOfYear;
  amount: number;
  description: string;
  info: string;
  target: string;
  importedAt: Date;
  bank: string;

  constructor(
    id: number,
    member_id: number,
    type: number,
    date: MonthOfYear,
    amount: number,
    description: string,
    info: string,
    target: string,
    importedAt: Date,
    bank: string,
  ) {
    this.id = id;
    this.member_id = member_id;
    this.type = type;
    this.date = date;
    this.amount = amount;
    this.description = description;
    this.info = info;
    this.target = target;
    this.importedAt = importedAt;
    this.bank = bank;
  }

  static from(raw: RawContribution): Contribution {
    const date = new Date(raw.data);
    return new Contribution(
      Number(raw.idtr),
      Number(raw.idcz),
      Number(raw.typ),
      MonthOfYear.from(date.getMonth() + 1, date.getFullYear()),
      Number(raw.kwota),
      raw.opis,
      raw.info,
      raw.kontrahent,
      new Date(raw.data_imp),
      raw.bank,
    );
  }
}

export { Contribution };
