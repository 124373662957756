import { MonthOfYear } from "./dates";
import { Member } from "./member";
import { Contribution } from "./contribution";
import { Contributions } from "./contributions";
import { Finances } from "./containers";
import { Action } from "./action";
import { Actions } from "./actions";
import { cost } from "./cost";

function createLogger(enabled: boolean) {
  if (enabled) {
    return (data: any, description?: string) => {
      console.log(description, JSON.stringify(data));
    };
  } else {
    return (_data: object, _description?: string) => {};
  }
}

function balanceForMonth(
  member: Member,
  previousBalance: number,
  monthOfYear: MonthOfYear,
  actions: Actions,
  contributions: Contributions,
): number {
  const log = createLogger(member.id == 341);
  log(monthOfYear, "BALANCE FOR MONTH");
  log(previousBalance, "previous balance");
  log(actions, "actions");
  log(contributions, "actions");
  let owes = cost(member, monthOfYear);
  log(owes, "owes");
  let remissions = actions.remissions();
  log(remissions, "remissions");
  for (const remission of remissions) {
    previousBalance += remission.amount;
  }
  let balanceSettings = actions.balanceSettings();
  log(balanceSettings, "balanceSettings");
  if (balanceSettings.length > 1) {
    throw new Error("More than 1 balance reset in a month");
  }
  if (balanceSettings.length > 0) {
    return balanceSettings[0].amount;
  }
  let exemptions = actions.exemptions();
  log(exemptions, "exemptions");
  if (exemptions.length > 1) {
    owes = 0;
  }
  const payed = contributions.sum();
  log(payed, "payed");
  log({}, "BALANCE FOR MONTH END");
  return previousBalance + payed - owes;
}

function balance(year: number, month: number, finances: Finances): number {
  const log = createLogger(finances.member[0].idcz == "341");
  const calculateFor = MonthOfYear.from(month, year);
  log(calculateFor, "calculateFor");
  const rawMember = finances.member[0];
  log(rawMember, "rawMember");
  const date = new Date(rawMember.data_uchwaly);
  log(date, "date");
  const memberJoinedOn = MonthOfYear.from(
    date.getMonth() + 1,
    date.getFullYear(),
  );
  log(memberJoinedOn, "memberJoinedOn");
  log(finances.balances, "balances");
  const startBalance = Number(
    finances["balances"].find(
      (element) =>
        element.rok == Math.max(memberJoinedOn.year._year, 2019).toString(),
    )?.kwota || 0,
  );
  log(startBalance, "startBalance");
  const contributions = finances.contributions.map((raw) =>
    Contribution.from(raw),
  );
  log(contributions, "contributions");
  const contributionsBeforeMemberJoined = contributions.filter(
    (contribution) =>
      contribution.date.year < memberJoinedOn.year ||
      (contribution.date.year == memberJoinedOn.year &&
        contribution.date.month < memberJoinedOn.month),
  );
  log(contributionsBeforeMemberJoined, "contributionsBeforeMember");
  let previousBalance = startBalance;
  log(previousBalance, "previousBalance");
  previousBalance += contributionsBeforeMemberJoined
    .map((e) => e.amount)
    .reduce((a, b) => a + b, 0);
  log(previousBalance, "previousBalance");
  const member = new Member(
    startBalance,
    finances.member[0].data_uchwaly,
    parseInt(finances.member[0].idcz),
  );
  log(member, "member");
  const contributionsPeriod = member.owesForPeriod();
  log(contributionsPeriod, "contributionsPeriod");
  const actions = new Actions(finances.actions.map((raw) => Action.from(raw)));
  log(actions, "actions");
  const memberContributions = new Contributions(contributions);
  log(memberContributions, "memberContributions");
  for (const year of contributionsPeriod.years(calculateFor.year)) {
    for (const month of contributionsPeriod.months(year)) {
      const monthOfYear: MonthOfYear = MonthOfYear.from(month, year);
      previousBalance = balanceForMonth(
        member,
        previousBalance,
        monthOfYear,
        actions.for(monthOfYear),
        memberContributions.for(monthOfYear),
      );
    }
  }
  return previousBalance;
}

export { balance };
